function getImageURLByUserAgent(ua) {
  if (ua.device.manufacturer === "Apple") {
    if (ua.os.family.includes("Mac")) {
      return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/mbp16-gray.png?alt=media&token=4c85b1d0-18a1-475a-ae1f-e358de3d6984";
    }
    if (ua.device.family.includes("iPhone")) {
      return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/iPhone14Pro_VioletProfond_Front-Side_400x540px.png?alt=media&token=12730a0c-8d5e-424a-b768-535b0f715eae";
    }
    if (ua.device.family.includes("iPad")) {
      return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/image-removebg-preview.png?alt=media&token=6632dcf3-a2a2-4ebc-b35c-343acee1ae66";
    }
  } else if (ua.os.family === "Android") {
    return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/Android-PNG-Clipart-Background.png?alt=media&token=67cdbf96-e1b9-48c3-8569-ea9eee891cf2";
  } else if (ua.os.family.includes("Windows")) {
    return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/image-removebg-preview%20(1).png?alt=media&token=a75cd0ad-5f21-4d2a-83d4-2b5296046dc9";
  } else if (ua.os.family.includes("Linux")) {
    return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/linux-logo-logo-brands-for-0.png?alt=media&token=dc8b14b3-d524-4dad-b656-2b01c151d04e";
  } else {
    return "https://firebasestorage.googleapis.com/v0/b/aksoa-company.appspot.com/o/other-removebg-preview.png?alt=media&token=dccfc173-bbe0-4d52-baf6-85ebf3ccda52";
  }
}
// Générer un nombre aléatoire à 6 chiffres
const generateRandomNumber = () => {
  const min = 10000000; // Valeur minimale à 6 chiffres
  const max = 99999999; // Valeur maximale à 6 chiffres
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
function genererIud() {
  const caracteresPermis =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let chaine = "";
  for (let i = 0; i < 16; i++) {
    const indiceAleatoire = Math.floor(Math.random() * caracteresPermis.length);
    chaine += caracteresPermis.charAt(indiceAleatoire);
  }
  return chaine;
}

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("fr-FR", options).format(date);
};
const formatDay = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  console.log(diffDays);
  if (diffDays === 0) {
    return "Aujourd'hui";
  } else if (diffDays === 1) {
    return "Il y a 1 jour";
  } else {
    return `Il y a ${diffDays} jours`;
  }
};
function getCountryName(code) {
  switch (code) {
    case "fr":
      return "France, Europe";
    case "uk":
      return "Royaume-Uni, Europe";
    case "es":
      return "Espagne, Europe";
    case "it":
      return "Italie, Europe";
    case "us":
      return "États-Unis, Europe";
    case "de":
      return "Allemagne, Europe";
    default:
      return "Pays inconnu";
  }
}
function getLanguageName(code) {
  switch (code) {
    case "fr":
      return "Français";
    case "en":
      return "Anglais";
    case "it":
      return "Italien";
    case "es":
      return "Espagnol";
    case "de":
      return "Allemand";
    default:
      return "Langue inconnue";
  }
}
function getTimezoneFromCountry(countryCode) {
  switch (countryCode) {
    case "fr":
      return "UTC+1:00"; // France
    case "uk":
      return "UTC+0:00"; // Royaume-Uni
    case "us":
      return "UTC-5:00"; // États-Unis
    case "it":
      return "UTC+1:00"; // Italie
    case "es":
      return "UTC+1:00"; // Espagne
    case "de":
      return "UTC+1:00"; // Allemagne
    default:
      return "Fuseau horaire inconnu";
  }
}

export {
  genererIud,
  generateRandomNumber,
  getImageURLByUserAgent,
  formatDate,
  formatDay,
  getCountryName,
  getLanguageName,
  getTimezoneFromCountry,
};
