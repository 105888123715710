import React from "react";
import "./home.css";
import Navbar from "../../components/bar/navbar";
import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

// icons
import { LuArrowDownRight, LuArrowUpRight, LuArrowRight } from "react-icons/lu";
import { RiFireLine, RiSparkling2Line } from "react-icons/ri";
import { TbChartLine } from "react-icons/tb";
import { Link } from "react-router-dom";

function Home() {
  const data = [
    {
      name: "Page A",
      uv: 2000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 4800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className="Home main">
      <Navbar title="Dashboard" />
      <div className="wrapper">
        <div className="category">
          <div className="list">
            <div className="item">
              <div className="top">
                <div className="ctn-icon">
                  <RiFireLine className="icon" />
                </div>
                <p>Total revenu</p>
              </div>
              <h4>368,75 €</h4>
            </div>
            <div className="item">
              <div className="top">
                <div className="ctn-icon">
                  <RiSparkling2Line className="icon" />
                </div>
                <p>Gains</p>
              </div>
              <div className="flex">
                <h4>+ 67,99 €</h4>
                <span className="green">
                  34,04 % <LuArrowUpRight className="icon" />
                </span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <div className="ctn-icon">
                  <RiFireLine className="icon" />
                </div>
                <p>Coûts</p>
              </div>
              <div className="flex">
                <h4>- 27,99 €</h4>
                <span className="red">
                  6,18 % <LuArrowDownRight className="icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="chart">
          <div className="top">
            <div className="ctn-icon">
              <TbChartLine className="icon" />
            </div>
            <p>Statistique</p>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={300} height={100} data={data}>
              <Tooltip />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#37A169"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#EE0C48"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="amt"
                stroke="#156BF5"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="project">
          <div className="flex">
            <Link to="/business" className="container business">
              33 Entreprises
              <LuArrowRight className="icon" />
            </Link>
            <Link to="/project" className="container project">
              46 Projets
              <LuArrowRight className="icon" />
            </Link>
            <Link to="/customer" className="container customer">
              43 Clients <LuArrowRight className="icon" />
            </Link>
          </div>
          <div className="chart">
            <div className="top">
              <div className="ctn-icon">
                <TbChartLine className="icon" />
              </div>
              <p>Statistique</p>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={150} height={40} data={data}>
                <Tooltip />
                <Bar dataKey="uv" fill="#35A169" />
                <Bar dataKey="pv" fill="#EE0C48" />
                <Bar dataKey="amt" fill="#156BF5" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
