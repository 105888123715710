import React from "react";
import "./search.css";

// icon
import { RiSearch2Line } from "react-icons/ri";

function Search({ placeholder, onChange, value }) {
  return (
    <label htmlFor="search-components" className="Search">
      <RiSearch2Line className="icon" />
      <input
        type="text"
        id="search-components"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </label>
  );
}

export default Search;
