import React, { useEffect, useState } from "react";
import "../customer/customer.css";
import Navbar from "../../components/bar/navbar";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../components/button";
import Search from "../../components/input/search";
import Pallet from "../../components/tags/pallet";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from "../../components/firebase/firebase";
import { formatDate, formatDay } from "../../components/function";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

// icons
import { IoIosClose, IoIosTime } from "react-icons/io";
import { HiPencil } from "react-icons/hi";

function Project() {
  let navigate = useNavigate();

  const [button, setButton] = useState("none");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setButton("disabled");
    // Fonction pour récupérer les données des clients depuis Firebase Firestore
    const fetchCustomers = async () => {
      try {
        const customersCollection = await firestore.collection("project").get();
        const customersData = await Promise.all(
          customersCollection.docs.map(async (doc) => {
            return {
              id: doc.data().name,
              description: doc.data().description,
              domain: doc.data().domain.name,
              registerDomain: doc.data().domain.register,
              createDomain: doc.data().domain && doc.data().domain.create,
              expireDomain: doc.data().domain && doc.data().domain.expire,
              priceDomain: doc.data().domain && doc.data().domain.price,
              host: doc.data().host.name,
              startHost: doc.data().host && doc.data().host.start,
              expireHost: doc.data().host && doc.data().host.expire,
              priceHost: doc.data().host && parseInt(doc.data().host.price),
              website: doc.data().website,
              price: doc.data().price,
              priceYear: doc.data().priceYear,
              cost:
                parseInt(doc.data().domain.price) +
                parseInt(doc.data().host.price),
              created: formatDate(doc.data().createdAt),
              update: formatDate(doc.data().createdAt),
              timestamp: doc.data().createdAt,
              docRef: doc.ref,
              index: doc.ref.id,
            };
          })
        );
        setCustomers(customersData);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };

    fetchCustomers();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Nom",
      width: 180,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => handleClick(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "domain",
      headerName: "Domaine",
      width: 230,
    },
    {
      field: "price",
      headerName: "Prix",
      width: 150,
      renderCell: (params) => (
        <div className="green fw-500">+{params.value}€</div>
      ),
    },
    {
      field: "priceYear",
      headerName: "Coût annuel",
      width: 150,
      renderCell: (params) => (
        <div className="green fw-500">+{params.value}€</div>
      ),
    },
    {
      field: "created",
      headerName: "Date création",
      width: 130,
    },
    {
      field: "update",
      headerName: "Dernière modification",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
    },
  ];

  const handleClick = (customer) => {
    var modal = document.getElementById("profilePreview");
    if (modal.classList.contains("active")) {
      modal.classList.remove("active");
    } else {
      modal.classList.add("active");
      setSelectedCustomer(customer);
    }
  };

  const handleDelete = async () => {
    setButton("loading");
    try {
      const batch = firestore.batch();
      selectedRows.forEach((row) => {
        const customer = customers.find((c) => c.id === row);
        if (customer && customer.docRef) {
          // Vérifie si la référence au document est définie
          batch.delete(customer.docRef); // Supprime le document Firestore
        }
      });
      await batch.commit();
      const updatedCustomers = customers.filter(
        (customer) => !selectedRows.includes(customer.id)
      );
      setCustomers(updatedCustomers);
      setSelectedRows([]);

      setButton("disabled");
    } catch (error) {
      console.error("Erreur lors de la suppression des projets :", error);
      setButton("none");
    }
  };

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length > 0) {
      setButton("none");
    } else {
      setButton("disabled");
    }
    setSelectedRows(selectionModel);
  };

  return (
    <div className="Customer main">
      <Navbar title="Projets" text={customers.length + " Projets"}>
        <Button text="+ Ajouter un projet" onClick={() => navigate("add")} />
      </Navbar>
      <div className="wrapper">
        <div className="topbar">
          <Search placeholder="Rechercher" />
          <Button
            text="Supprimer"
            color="red"
            onClick={handleDelete}
            state={button}
          />
        </div>
        <DataGrid
          rows={customers} // Utilisez les données des clients récupérées depuis Firebase
          columns={columns}
          onRowSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          className="table"
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
      <div className="ctn-profile" id="profilePreview">
        <div className="bg" onClick={() => handleClick()}></div>
        <div className="profile">
          <div className="padding">
            <div className="tab">
              <h5>Project Preview</h5>
              <div>
                <Link
                  className="button-fill"
                  to={"edit/" + (selectedCustomer && selectedCustomer.index)}
                >
                  <HiPencil className="icon-button" />
                  Editer
                </Link>
                <PDFDownloadLink
                  document={<FacturePDF data={selectedCustomer} />}
                  fileName="facture.pdf"
                  style={{ textDecoration: "none" }}
                >
                  {() => (
                    <Button
                      text="Générer une facture"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </PDFDownloadLink>

                <IoIosClose className="icon" onClick={() => handleClick()} />
              </div>
            </div>
            <div className="ctn-infos">
              <div className="ctn-text">
                <div className="ctn-title">
                  <h4>{selectedCustomer && selectedCustomer.id}</h4>
                  <Pallet
                    text={
                      selectedCustomer && formatDay(selectedCustomer.timestamp)
                    }
                  >
                    <IoIosTime />
                  </Pallet>
                </div>
              </div>
            </div>
            <div className="ctn-mid">
              <div>
                <p className="text-sm">Prix</p>
                <h5 className="green">
                  {selectedCustomer && selectedCustomer.price}€
                </h5>
              </div>
              <div>
                <p className="text-sm">Gains</p>
                <h5 className="green">
                  +
                  {selectedCustomer &&
                    Math.round((selectedCustomer.priceYear / 12) * 10) / 10}
                  €/m
                </h5>
              </div>
              <div>
                <p className="text-sm">Coûts</p>
                <h5 className="red">
                  -
                  {selectedCustomer &&
                    Math.round((selectedCustomer.cost / 12) * 10) / 10}
                  €/m
                </h5>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Projet details</p>
              <div className="flex">
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Description</p>
                    <div>
                      <p>
                        {selectedCustomer &&
                        selectedCustomer.description !== null
                          ? selectedCustomer.description
                          : "Pas de description"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Création du projet</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.created}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Domaine</p>
              <div className="flex">
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Domaine</p>
                    <div>
                      <a
                        className="active"
                        href={selectedCustomer && selectedCustomer.website}
                      >
                        {selectedCustomer && selectedCustomer.domain}
                      </a>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Enregistreur</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.createDomain}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Prix</p>
                    <div>
                      <p>
                        {selectedCustomer && selectedCustomer.priceDomain}€/an
                      </p>
                    </div>
                  </div>
                </div>
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Création</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.createDomain}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Éxpiration</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.expireDomain}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Hébergeur</p>
              <div className="flex">
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Nom</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.host}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Prix</p>
                    <div>
                      <p>
                        {selectedCustomer && selectedCustomer.priceHost}€/an
                      </p>
                    </div>
                  </div>
                </div>
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Création</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.startHost}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Éxpiration</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.expireHost}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  logo: {
    width: 200,
    height: 100,
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ctnFlex: {
    width: "50%",
  },
  container: {
    width: "45%",
    color: "#5A5B5B",
    fontSize: 12,
    marginTop: 20,
    lineHeight: 1.5,
  },
  title: {
    fontSize: 13,
    color: "black",
    marginBottom: 5,
  },
});

const FacturePDF = ({ data }) => {
  return (
    <Document>
      {data && (
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 26 }}>
              Louk<Text style={{ color: "#135bf6" }}>a.</Text>
            </Text>
            <Text style={{ fontSize: 32 }}>Facture</Text>
          </View>
          <View
            style={[
              styles.flex,
              {
                marginTop: 50,
                color: "#5A5B5B",
                fontSize: 11,
                lineHeight: 1.5,
              },
            ]}
          >
            <View style={{ width: 200 }}>
              <Text>Bonjour, {data.id}.</Text>
              <Text>
                Merci d'avoir fait vos achats dans notre magasin et pour votre
                commande.
              </Text>
            </View>
            <View style={[styles.ctnFlex, { textAlign: "right" }]}>
              <Text>Numéro de commande : 80000002</Text>
              <Text>Réf° client : #2849AB</Text>
              <Text>Date : {formatDate(Date.now())}</Text>
            </View>
          </View>
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 80,
                paddingBottom: 5,
                borderBottom: "1px solid #5A5B5B",
                color: "#5A5B5B",
                fontSize: 12,
              }}
            >
              <Text style={{ width: 260 }}>Produit</Text>
              <Text style={{ width: 100 }}>Réf°</Text>
              <Text style={{ width: 100 }}>Quantité</Text>
              <Text>Prix</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 10,
                paddingBottom: 10,
                borderBottom: "1px solid #eee",
                color: "#5A5B5B",
                fontSize: 12,
              }}
            >
              <Text style={{ width: 260, color: "#135bf6" }}>
                Site Internet {data.domain}
              </Text>
              <Text style={{ width: 100 }}>#284HF69</Text>
              <Text style={{ width: 100 }}>1</Text>
              <Text>{data.price}€</Text>
            </View>
            {(data.registerDomain || data.registerHost) && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottom: "1px solid #eee",
                  color: "#5A5B5B",
                  fontSize: 12,
                }}
              >
                <Text style={{ width: 260, color: "#135bf6" }}>
                  Maintenance (hébergement, ...)
                </Text>
                <Text style={{ width: 100 }}>#3857YS8</Text>
                <Text style={{ width: 100 }}>1</Text>
                <Text>
                  {parseFloat(data.priceDomain) + parseFloat(data.priceHost)}€
                </Text>
              </View>
            )}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 20,
                color: "#5A5B5B",
                fontSize: 12,
                lineHeight: 1.5,
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginRight: 30,
                }}
              >
                <Text>Sous-total : </Text>
                <Text>TVA : </Text>
                <Text style={{ fontSize: 14, marginTop: 5, color: "black" }}>
                  Total :
                </Text>
              </View>
              <View style={{ display: "flex", alignItems: "flex-end" }}>
                <Text>
                  {(parseFloat(data.priceDomain) +
                    parseFloat(data.priceHost) +
                    parseFloat(data.price)) *
                    0.85}
                  €
                </Text>
                <Text>15%</Text>
                <Text style={{ fontSize: 14, marginTop: 5, color: "black" }}>
                  {parseFloat(data.priceDomain) +
                    parseFloat(data.priceHost) +
                    parseFloat(data.price)}
                  €
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 20,
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <View style={styles.container}>
              <Text style={styles.title}>INFORMATIONS FACTURATION</Text>
              <Text>{data.id}</Text>
              <Text>4 Rue de la liberté</Text>
              <Text>Grenoble 38000, France</Text>
              <Text>Téléphone : {data.phone}</Text>
              <Text>Email : {data.email}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.title}>MODE DE PAIEMENT</Text>
              <Text>Carte de crédit</Text>
              <Text>Type de carte de crédit : Visa</Text>
              <Text>Numéro de transaction : 4185939336</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.title}>INFORMATIONS LIVRAISON</Text>
              <Text>{data.id}</Text>
              <Text>4 Rue de la liberté</Text>
              <Text>Grenoble 38000, France</Text>
              <Text>Téléphone : {data.phone}</Text>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default Project;
