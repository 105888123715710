import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyCrLp9f7SNmfoU8DvkQyy0mm8CTcVKNyb4",
  authDomain: "loukaaa.firebaseapp.com",
  projectId: "loukaaa",
  storageBucket: "loukaaa.appspot.com",
  messagingSenderId: "738175886104",
  appId: "1:738175886104:web:e81ca319bd0bd5b0ddb46d",
  measurementId: "G-YJ19ET6ECH",
});

export const storage = firebase.storage();
export const firestore = firebase.firestore();
