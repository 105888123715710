import React from "react";
import "./pallet.css";

function Pallet({ online, text, children }) {
  return (
    <div className="Pallet">
      <div className="flex">
        {children}
        {online && <span></span>}
        {text}
      </div>
    </div>
  );
}

export default Pallet;
