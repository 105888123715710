import React, { useState } from "react";
import "../customer/add.css";
import Navbar from "../../components/bar/navbar";
import Input from "../../components/input";
import Button from "../../components/button";
import { firestore } from "../../components/firebase/firebase";
import { useNavigate } from "react-router-dom";

function AddProject() {
  const navigate = useNavigate();

  const [button, setButton] = useState("none");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [domain, setDomain] = useState("");
  const [domainRegister, setDomainRegister] = useState("");
  const [domainCreate, setDomainCreate] = useState("");
  const [domainExpire, setDomainExpire] = useState("");
  const [domainPrice, setDomainPrice] = useState("");
  const [host, setHost] = useState("");
  const [hostStart, setHostStart] = useState("");
  const [hostExpire, setHostExpire] = useState("");
  const [hostPrice, setHostPrice] = useState("");
  const [price, setPrice] = useState("");
  const [priceYear, setPriceYear] = useState("");

  const handleSave = () => {
    setButton("loading");
    if (name && website && price && domain) {
      // Enregistrez les informations du client dans Firestore
      firestore
        .collection("project")
        .doc(name.toLowerCase().replace(/ /g, ""))
        .set({
          name: name,
          id: name.toLowerCase().replace(/ /g, ""),
          description: description ? description : null,
          website: website,
          domain: {
            name: domain,
            register: domainRegister,
            create: domainCreate,
            expire: domainExpire,
            price: domainPrice ? domainPrice : 0,
          },
          host: {
            name: host,
            start: hostStart,
            expire: hostExpire,
            price: hostPrice ? hostPrice : 0,
          },
          price,
          priceYear: priceYear ? priceYear : 0,
          createdAt: Date.now(),
          updateAt: Date.now(),
        })
        .then(() => {
          console.log("Projet enregistré avec succès !");
          navigate("/project");
          // Redirigez ou affichez un message de succès selon votre besoin
        })
        .catch((error) => {
          console.error("Erreur lors de l'enregistrement du projet :", error);
          setButton("none");
        });
    } else {
      alert(
        "Veuillez remplir tous les champs avant de cliquer sur Enregistrer."
      );
      setButton("none");
    }
  };

  return (
    <div className="AddCustomer main">
      <Navbar title="Ajouter un projet" url="/project" />
      <div className="wrapper">
        <div className="form">
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Nom du projet</h5>
              </div>
              <div>
                <Input
                  id="name"
                  label="Nom"
                  placeholder="La belle idée"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Description</h5>
              </div>
              <div>
                <textarea
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Informations</h5>
              </div>
              <div>
                <Input
                  id="website"
                  label="Site Internet"
                  placeholder="https://domain.fr/"
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Nom de domaine</h5>
              </div>
              <div>
                <Input
                  id="domain"
                  label="Nom de domaine"
                  placeholder="labelleidee.fr"
                  onChange={(e) => setDomain(e.target.value)}
                />
                <Input
                  id="registerDomain"
                  label="Enregistreur"
                  placeholder="Hostinger"
                  onChange={(e) => setDomainRegister(e.target.value)}
                />
                <Input
                  id="createDomain"
                  label="Date création"
                  placeholder="28/12/2018"
                  onChange={(e) => setDomainCreate(e.target.value)}
                />
                <Input
                  id="expireDomain"
                  label="Date éxpiration"
                  placeholder="28/12/2023"
                  onChange={(e) => setDomainExpire(e.target.value)}
                />
                <Input
                  id="priceDomain"
                  label="Coût par an"
                  placeholder="10€"
                  onChange={(e) => setDomainPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Hébergeur</h5>
              </div>
              <div>
                <Input
                  id="host"
                  label="nom de l'hébergeur"
                  placeholder="Hostinger"
                  onChange={(e) => setHost(e.target.value)}
                />
                <Input
                  id="startHost"
                  label="Date création"
                  placeholder="28/12/2018"
                  onChange={(e) => setHostStart(e.target.value)}
                />
                <Input
                  id="expireHost"
                  label="Date éxpiration"
                  placeholder="28/12/2023"
                  onChange={(e) => setHostExpire(e.target.value)}
                />
                <Input
                  id="priceHost"
                  label="Coût par an"
                  placeholder="39.99€"
                  onChange={(e) => setHostPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Prix du projet</h5>
              </div>
              <div>
                <Input
                  id="price"
                  label="Prix"
                  placeholder="700€"
                  onChange={(e) => setPrice(e.target.value)}
                />
                <Input
                  id="priceYear"
                  label="Prix maintenance"
                  placeholder="150€"
                  onChange={(e) => setPriceYear(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="ctn-button">
            <Button text="Enregistrer" onClick={handleSave} state={button} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProject;
