import React, { Fragment, useEffect, useState } from "react";
import "./sidebar.css";
import { Outlet, useNavigate } from "react-router-dom";

// icons
import {
  RiHome5Line,
  RiHome5Fill,
  RiBox3Line,
  RiBox3Fill,
  RiUser4Line,
  RiUser4Fill,
  RiSearch2Line,
  RiBuildingLine,
  RiBuildingFill,
} from "react-icons/ri";
import { CgArrowsExchangeV } from "react-icons/cg";

function Sidebar() {
  const [url, setUrl] = useState("/");

  const navigate = useNavigate();

  const navigateTo = (url) => {
    navigate(url);
    setUrl(url);
  };

  useEffect(() => {
    setUrl(window.location.pathname);
  }, []);

  return (
    <Fragment>
      <div className="Sidebar">
        <div className="top">
          <div className="account">
            <div>
              <div className="ctn-image">
                <img
                  src="https://cdn.osxdaily.com/wp-content/uploads/2020/10/macos-big-sur-wallpaper-1-scaled.jpg"
                  alt="logo user"
                />
                <div className="ctn-status">
                  <div className="status"></div>
                </div>
              </div>
              <div>
                <p>Loukaaa</p>
                <span>Owner</span>
              </div>
            </div>
            <div>
              <CgArrowsExchangeV className="icon" />
            </div>
          </div>
          <div className="search">
            <RiSearch2Line className="icon" />
            Rechercher
          </div>
          <div className="menu">
            {url === "/" ? (
              <div className="item active">
                <RiHome5Fill className="icon" />
                Dashboard
              </div>
            ) : (
              <div className="item" onClick={() => navigateTo("/")}>
                <RiHome5Line className="icon" />
                Dashboard
              </div>
            )}
            {url.includes("/business") ? (
              <div className="item active">
                <RiBuildingFill className="icon" />
                Entreprise
              </div>
            ) : (
              <div className="item" onClick={() => navigateTo("/business")}>
                <RiBuildingLine className="icon" />
                Entreprise
              </div>
            )}
            {url.includes("/project") ? (
              <div className="item active">
                <RiBox3Fill className="icon" />
                Projet
              </div>
            ) : (
              <div className="item" onClick={() => navigateTo("/project")}>
                <RiBox3Line className="icon" />
                Projet
              </div>
            )}
            {url.includes("/customer") ? (
              <div className="item active">
                <RiUser4Fill className="icon" />
                Client
              </div>
            ) : (
              <div className="item" onClick={() => navigateTo("/customer")}>
                <RiUser4Line className="icon" />
                Client
              </div>
            )}
          </div>
        </div>
        <div className="bottom">
          <span>POWERED BY </span>
          <h4>Loukaaa.</h4>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
}

export default Sidebar;
