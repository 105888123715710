import React, { useState } from "react";
import "../customer/add.css";
import Navbar from "../../components/bar/navbar";
import Input from "../../components/input";
import Button from "../../components/button";
import Select from "react-select";
import { storage, firestore } from "../../components/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { country, language } from "../../components/function/var";

// icons
import { IoMdAdd } from "react-icons/io";

function AddBusiness() {
  const navigate = useNavigate();

  const [button, setButton] = useState("none");

  const [emails, setEmails] = useState([""]);
  const [phones, setPhones] = useState([""]);
  const [selectedImage, setSelectedImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleAddEmailInput = () => {
    setEmails([...emails, ""]);
  };

  const handleAddPhoneInput = () => {
    setPhones([...phones, ""]);
  };

  const handleInputChange = (index, value, inputType) => {
    if (inputType === "email") {
      const newEmails = [...emails];
      newEmails[index] = value;
      setEmails(newEmails);
    } else if (inputType === "phone") {
      const newPhones = [...phones];
      newPhones[index] = value;
      setPhones(newPhones);
    } else if (inputType === "name") {
      setName(value);
    } else if (inputType === "description") {
      setDescription(value);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.size <= 2000000 &&
        (file.type === "image/jpeg" || file.type === "image/png")
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Veuillez sélectionner une image au format JPEG ou PNG de moins de 2MB."
        );
      }
    }
  };

  const handleSave = () => {
    setButton("loading");
    if (name && selectedLanguage && selectedCountry) {
      if (selectedImage !== "") {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`business/${Date.now()}`);
        imageRef
          .putString(selectedImage, "data_url")
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((downloadURL) => {
            // Enregistrez les informations du client dans Firestore
            firestore
              .collection("business")
              .doc(name.toLowerCase().replace(/ /g, ""))
              .set({
                imageURL: downloadURL,
                name: name,
                id: name.toLowerCase().replace(/ /g, ""),
                description: description ? description : null,
                language: selectedLanguage,
                country: selectedCountry,
                emails: emails,
                phones: phones,
                createdAt: Date.now(),
                updatedAt: Date.now(),
                // Ajoutez d'autres champs si nécessaire
              })
              .then(() => {
                console.log("Client enregistré avec succès !");
                navigate("/business");
                // Redirigez ou affichez un message de succès selon votre besoin
              })
              .catch((error) => {
                console.error(
                  "Erreur lors de l'enregistrement du client :",
                  error
                );
                setButton("none");
              });
          })
          .catch((error) => {
            console.error(
              "Erreur lors de l'envoi de l'image sur Firebase Storage :",
              error
            );
            setButton("none");
          });
      } else {
        // Enregistrez les informations du client dans Firestore
        firestore
          .collection("business")
          .doc(name.toLowerCase().replace(/ /g, ""))
          .set({
            imageURL:
              "https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.png",
            name: name,
            id: name.toLowerCase().replace(/ /g, ""),
            description: description ? description : null,
            language: selectedLanguage,
            country: selectedCountry,
            emails: emails,
            phones: phones,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            // Ajoutez d'autres champs si nécessaire
          })
          .then(() => {
            console.log("Client enregistré avec succès !");
            navigate("/business");
            // Redirigez ou affichez un message de succès selon votre besoin
          })
          .catch((error) => {
            console.error("Erreur lors de l'enregistrement du client :", error);
            setButton("none");
          });
      }
    } else {
      alert(
        "Veuillez remplir tous les champs avant de cliquer sur Enregistrer."
      );
      setButton("none");
    }
  };

  return (
    <div className="AddCustomer main">
      <Navbar title="Ajouter une entreprise" url="/business" />
      <div className="wrapper">
        <div className="form">
          <div className="form-group logo">
            <div className="inner">
              <div className="flex">
                <div className="container">
                  {selectedImage ? (
                    <img src={selectedImage} alt="form" />
                  ) : (
                    <img
                      src="https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.png"
                      alt="form"
                    />
                  )}
                  <div>
                    <p>Logo</p>
                    <span className="text-sm">PNG, JPG moins de 2MB</span>
                  </div>
                </div>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="upload"
                />
                <label htmlFor="upload">
                  <p className="button-fill">Charger</p>
                </label>
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Nom de l'entreprise</h5>
              </div>
              <div>
                <Input
                  id="name"
                  label="Nom"
                  placeholder="Louka"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group add-input">
            <div className="inner">
              <div className="title">
                <h5>Contact email</h5>
              </div>
              <div className="flex">
                <div>
                  {emails.map((email, index) => (
                    <div key={index} className="container">
                      <Input
                        id={`email-${index}`}
                        label={`Email ${index + 1}`}
                        placeholder="email@mail.com"
                        value={email}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value, "email")
                        }
                      />
                    </div>
                  ))}
                </div>
                <Button text="Ajouter" fill onClick={handleAddEmailInput}>
                  <div className="ctn-icon-form">
                    <IoMdAdd />
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="form-group add-input">
            <div className="inner">
              <div className="title">
                <h5>Contact Téléphone</h5>
              </div>
              <div className="flex">
                <div>
                  {phones.map((phone, index) => (
                    <div key={index} className="container">
                      <Input
                        id={`phone-${index}`}
                        label={`Téléphone ${index + 1}`}
                        placeholder="(33) 781646204"
                        value={phone}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value, "phone")
                        }
                      />
                    </div>
                  ))}
                </div>
                <Button text="Ajouter" fill onClick={handleAddPhoneInput}>
                  <div className="ctn-icon-form">
                    <IoMdAdd />
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Informations</h5>
              </div>
              <div>
                <div className="select">
                  <span className="text-sm">Pays</span>
                  <Select
                    options={country}
                    onChange={(e) => setSelectedCountry(e.value)}
                  />
                </div>
                <div className="select">
                  <span className="text-sm">Langue</span>
                  <Select
                    options={language}
                    onChange={(e) => setSelectedLanguage(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Description</h5>
              </div>
              <div>
                <textarea
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="ctn-button">
            <Button text="Enregistrer" onClick={handleSave} state={button} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBusiness;
