import React, { Fragment } from "react";
import "./button.css";
import LoaderComponent from "../loader/index.jsx";

// icons
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import { BsCheckLg } from "react-icons/bs";

function Button({
  text,
  onClick,
  state,
  icon,
  color,
  style,
  children,
  id,
  fill,
}) {
  return (
    <Fragment>
      {state === "loading" ? (
        <button
          id={id}
          className={
            "ButtonComponent loadingtrue icon" +
            (icon === undefined ? "false" : icon) +
            " " +
            (color === undefined ? "false" : color) +
            " " +
            (fill === true && "fill")
          }
          onClick={onClick}
          style={style}
        >
          <div className="text">
            {children}
            {text} {icon && <HiOutlineArrowNarrowRight className="icon" />}
          </div>
          <div className="loader">
            <LoaderComponent />
          </div>
        </button>
      ) : state === "disabled" ? (
        <button
          className={"ButtonComponent icon red disabled"}
          onClick={onClick}
          style={style}
          disabled={true}
        >
          {children}
          {text} {icon && <HiOutlineArrowNarrowRight className="icon" />}
        </button>
      ) : state === "then" ? (
        <button
          className={
            "ButtonComponent green loadingtrue icon" +
            (icon === undefined ? "false" : icon)
          }
          onClick={onClick}
          style={style}
        >
          <div className="text">
            {children}
            {text} {icon && <HiOutlineArrowNarrowRight className="icon" />}
          </div>
          <div className="icon-event">
            <BsCheckLg color="white" />
          </div>
        </button>
      ) : state === "catch" ? (
        <button
          className={
            "ButtonComponent red loadingtrue icon" +
            (icon === undefined ? "false" : icon)
          }
          onClick={onClick}
          style={style}
        >
          <div className="text">
            {children}
            {text} {icon && <HiOutlineArrowNarrowRight className="icon" />}
          </div>
          <div className="icon-event">
            <VscChromeClose />
          </div>
        </button>
      ) : (
        <button
          className={
            "ButtonComponent icon" +
            (icon === undefined ? "false" : icon) +
            " " +
            (color === undefined ? "false" : color) +
            " " +
            (fill === true && "fill")
          }
          onClick={onClick}
          style={style}
        >
          {children}
          {text} {icon && <HiOutlineArrowNarrowRight className="icon" />}
        </button>
      )}
    </Fragment>
  );
}

export default Button;
