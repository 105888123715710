import React, { useState, useEffect } from "react";
import "../customer/add.css";
import Navbar from "../../components/bar/navbar";
import Input from "../../components/input";
import Button from "../../components/button";
import { firestore } from "../../components/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";

function EditProject() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [button, setButton] = useState("none");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [domain, setDomain] = useState("");
  const [domainRegister, setDomainRegister] = useState("");
  const [domainCreate, setDomainCreate] = useState("");
  const [domainExpire, setDomainExpire] = useState("");
  const [domainPrice, setDomainPrice] = useState("");
  const [host, setHost] = useState("");
  const [hostStart, setHostStart] = useState("");
  const [hostExpire, setHostExpire] = useState("");
  const [hostPrice, setHostPrice] = useState("");
  const [price, setPrice] = useState("");
  const [priceYear, setPriceYear] = useState("");

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectRef = firestore.collection("project").doc(id);
        const doc = await projectRef.get();
        if (doc.exists) {
          const projectData = doc.data();
          setName(projectData.name);
          setDescription(projectData.description || "");
          setWebsite(projectData.website);
          setDomain(projectData.domain.name || "");
          setDomainRegister(projectData.domain.register || "");
          setDomainCreate(projectData.domain.create || "");
          setDomainExpire(projectData.domain.expire || "");
          setDomainPrice(projectData.domain.price || "");
          setHost(projectData.host.name || "");
          setHostStart(projectData.host.start || "");
          setHostExpire(projectData.host.expire || "");
          setHostPrice(projectData.host.price || "");
          setPrice(projectData.price || "");
          setPriceYear(projectData.priceYear || "");
        } else {
          console.log("Aucun projet trouvé avec cet identifiant");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du projet :", error);
      }
    };

    fetchProject();
  }, [id]);

  const handleSave = () => {
    setButton("loading");
    if (name && website && price && domain) {
      // Enregistrez les informations du projet dans Firestore
      firestore
        .collection("project")
        .doc(id)
        .update({
          name: name,
          description: description ? description : null,
          website: website,
          domain: {
            name: domain,
            register: domainRegister,
            create: domainCreate,
            expire: domainExpire,
            price: domainPrice ? domainPrice : 0,
          },
          host: {
            name: host,
            start: hostStart,
            expire: hostExpire,
            price: hostPrice ? hostPrice : 0,
          },
          price,
          priceYear: priceYear ? priceYear : 0,
          updateAt: Date.now(),
        })
        .then(() => {
          console.log("Projet mis à jour avec succès !");
          navigate("/project");
          // Redirigez ou affichez un message de succès selon votre besoin
        })
        .catch((error) => {
          console.error("Erreur lors de la mise à jour du projet :", error);
          setButton("none");
        });
    } else {
      alert(
        "Veuillez remplir tous les champs avant de cliquer sur Enregistrer."
      );
      setButton("none");
    }
  };

  return (
    <div className="AddCustomer main">
      <Navbar title="Modifier un projet" url="/project" />
      <div className="wrapper">
        <div className="form">
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Nom du projet</h5>
              </div>
              <div>
                <Input
                  id="name"
                  label="Nom"
                  placeholder="La belle idée"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Description</h5>
              </div>
              <div>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Informations</h5>
              </div>
              <div>
                <Input
                  id="website"
                  label="Site Internet"
                  placeholder="https://domain.fr/"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Nom de domaine</h5>
              </div>
              <div>
                <Input
                  id="domain"
                  label="Nom de domaine"
                  placeholder="labelleidee.fr"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />
                <Input
                  id="registerDomain"
                  label="Enregistreur"
                  placeholder="Hostinger"
                  value={domainRegister}
                  onChange={(e) => setDomainRegister(e.target.value)}
                />
                <Input
                  id="createDomain"
                  label="Date création"
                  placeholder="28/12/2018"
                  value={domainCreate}
                  onChange={(e) => setDomainCreate(e.target.value)}
                />
                <Input
                  id="expireDomain"
                  label="Date éxpiration"
                  placeholder="28/12/2023"
                  value={domainExpire}
                  onChange={(e) => setDomainExpire(e.target.value)}
                />
                <Input
                  id="priceDomain"
                  label="Coût par an"
                  placeholder="10€"
                  value={domainPrice}
                  onChange={(e) => setDomainPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Hébergeur</h5>
              </div>
              <div>
                <Input
                  id="host"
                  label="Nom de l'hébergeur"
                  placeholder="Hostinger"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                />
                <Input
                  id="startHost"
                  label="Date création"
                  placeholder="28/12/2018"
                  value={hostStart}
                  onChange={(e) => setHostStart(e.target.value)}
                />
                <Input
                  id="expireHost"
                  label="Date éxpiration"
                  placeholder="28/12/2023"
                  value={hostExpire}
                  onChange={(e) => setHostExpire(e.target.value)}
                />
                <Input
                  id="priceHost"
                  label="Coût par an"
                  placeholder="39.99€"
                  value={hostPrice}
                  onChange={(e) => setHostPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group normal">
            <div className="inner">
              <div className="title">
                <h5>Prix du projet</h5>
              </div>
              <div>
                <Input
                  id="price"
                  label="Prix"
                  placeholder="700€"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <Input
                  id="priceYear"
                  label="Prix maintenance"
                  placeholder="150€"
                  value={priceYear}
                  onChange={(e) => setPriceYear(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="ctn-button">
            <Button text="Enregistrer" onClick={handleSave} state={button} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProject;
