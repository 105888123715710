import React, { useEffect, useState } from "react";
import "../customer/customer.css";
import Navbar from "../../components/bar/navbar";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../components/button";
import Search from "../../components/input/search";
import Pallet from "../../components/tags/pallet";
import { Link, useNavigate } from "react-router-dom";
import { storage, firestore } from "../../components/firebase/firebase";
import {
  formatDate,
  formatDay,
  getCountryName,
  getLanguageName,
  getTimezoneFromCountry,
} from "../../components/function";

// icons
import { IoIosClose, IoIosTime } from "react-icons/io";
import { HiPencil } from "react-icons/hi";

function Business() {
  let navigate = useNavigate();

  const [button, setButton] = useState("none");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setButton("disabled");
    // Fonction pour récupérer les données des clients depuis Firebase Firestore
    const fetchCustomers = async () => {
      try {
        const customersCollection = await firestore
          .collection("business")
          .get();
        const customersData = customersCollection.docs.map((doc) => ({
          id: doc.data().name,
          email: doc.data().emails[0],
          emails: doc.data().emails,
          phone: doc.data().phones[0],
          phones: doc.data().phones,
          created: formatDate(doc.data().createdAt),
          update: formatDate(doc.data().updatedAt),
          timestamp: doc.data().createdAt,
          image: doc.data().imageURL,
          country: getCountryName(doc.data().country),
          language: getLanguageName(doc.data().language),
          timezone: getTimezoneFromCountry(doc.data().country),
          description: doc.data().description,
          docRef: doc.ref,
          index: doc.ref.id,
        }));
        setCustomers(customersData);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };

    fetchCustomers();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Nom",
      width: 180,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => handleClick(params.row)}
        >
          <img
            src={params.row.image}
            alt={params.row.id}
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: 20,
              height: 20,
            }}
          />
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
      renderCell: (params) => <div className="active">{params.value}</div>,
    },
    {
      field: "phone",
      headerName: "Téléphone",
      width: 210,
      renderCell: (params) => <div className="active">{params.value}</div>,
    },
    {
      field: "created",
      headerName: "Date création",
      width: 200,
    },
    {
      field: "update",
      headerName: "Dernière modification",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 200,
    },
  ];

  const handleClick = (customer) => {
    var modal = document.getElementById("profilePreview");
    if (modal.classList.contains("active")) {
      modal.classList.remove("active");
    } else {
      modal.classList.add("active");
      setSelectedCustomer(customer);
    }
  };

  const handleDelete = async () => {
    setButton("loading");
    try {
      const batch = firestore.batch();
      selectedRows.forEach((row) => {
        const customer = customers.find((c) => c.id === row);
        if (customer && customer.docRef) {
          // Vérifie si la référence au document est définie
          batch.delete(customer.docRef); // Supprime le document Firestore
          // Supprime l'image du storage si nécessaire
          if (
            customer.image !==
            "https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.png"
          ) {
            storage.refFromURL(customer.image).delete();
          }
        }
      });
      await batch.commit();
      const updatedCustomers = customers.filter(
        (customer) => !selectedRows.includes(customer.id)
      );
      setCustomers(updatedCustomers);
      setSelectedRows([]);

      setButton("disabled");
    } catch (error) {
      console.error("Erreur lors de la suppression des clients :", error);
      setButton("none");
    }
  };

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length > 0) {
      setButton("none");
    } else {
      setButton("disabled");
    }
    setSelectedRows(selectionModel);
  };

  return (
    <div className="Customer main">
      <Navbar title="Entreprise" text={customers.length + " Entreprise"}>
        <Button
          text="+ Ajouter une entreprise"
          onClick={() => navigate("add")}
        />
      </Navbar>
      <div className="wrapper">
        <div className="topbar">
          <Search placeholder="Rechercher" />
          <Button
            text="Supprimer"
            color="red"
            onClick={handleDelete}
            state={button}
          />
        </div>
        <DataGrid
          rows={customers} // Utilisez les données des clients récupérées depuis Firebase
          columns={columns}
          onRowSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          className="table"
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
      <div className="ctn-profile" id="profilePreview">
        <div className="bg" onClick={() => handleClick()}></div>
        <div className="profile">
          <div className="padding">
            <div className="tab">
              <h5>Entreprise Preview</h5>
              <div>
                <Link
                  className="button-fill"
                  to={"edit/" + (selectedCustomer && selectedCustomer.index)}
                >
                  <HiPencil className="icon-button" />
                  Editer
                </Link>
                <IoIosClose className="icon" onClick={() => handleClick()} />
              </div>
            </div>
            <div className="ctn-infos" style={{ marginBottom: 20 }}>
              <img
                src={selectedCustomer && selectedCustomer.image}
                alt="logo"
              />
              <div className="ctn-text">
                <div className="ctn-title">
                  <h4>{selectedCustomer && selectedCustomer.id}</h4>
                  <Pallet
                    text={
                      selectedCustomer && formatDay(selectedCustomer.timestamp)
                    }
                  >
                    <IoIosTime />
                  </Pallet>
                </div>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Entreprise details</p>
              <div className="flex">
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Téléphone</p>
                    <div>
                      {selectedCustomer &&
                        selectedCustomer.phones.map((phone, index) => (
                          <p className="active" key={index}>
                            {phone}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Email</p>
                    <div>
                      {selectedCustomer &&
                        selectedCustomer.emails.map((email, index) => (
                          <p className="active" key={index}>
                            {email}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Localisation</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.country}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Langue</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.language}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Fuseau horaire</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.timezone}</p>
                    </div>
                  </div>
                </div>
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Description</p>
                    <div>
                      <p>
                        {selectedCustomer &&
                        selectedCustomer.description !== null
                          ? selectedCustomer.description
                          : "Pas de description"}
                      </p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Création</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.created}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
