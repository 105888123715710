const api = "https://us-central1-aksoa-company.cloudfunctions.net/api";
// const api = "http://localhost:3008";

var errorMsg =
  "Une erreur s'est produite lors de la récupération des données, Réessayer plus tard...";

const country = [
  { value: "fr", label: "France" },
  { value: "uk", label: "Royaume-Uni" },
  { value: "us", label: "États-Unis" },
  { value: "it", label: "Italie" },
  { value: "es", label: "Espagne" },
  { value: "de", label: "Allemagne" },
];

const language = [
  { value: "fr", label: "Français" },
  { value: "en", label: "Anglais" },
  { value: "it", label: "Italien" },
  { value: "es", label: "Espagnol" },
  { value: "de", label: "Allemand" },
];
export { api, errorMsg, country, language };
