import React from "react";
import "./navbar.css";

import { Link } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

function Navbar({ title, text, url, children }) {
  return (
    <div className="Navbar">
      <div className="flex">
        {url && (
          <Link to={url}>
            <RiArrowLeftSLine />
          </Link>
        )}
        <div>
          <h3>{title}</h3>
          <span>{text}</span>
        </div>
      </div>
      {children}
    </div>
  );
}

export default Navbar;
