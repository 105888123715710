import React, { useEffect, useState } from "react";
import "./add.css";
import Navbar from "../../components/bar/navbar";
import Input from "../../components/input";
import Button from "../../components/button";
import Select from "react-select";
import { firestore, storage } from "../../components/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { country, language } from "../../components/function/var";

// icons
import { IoMdAdd } from "react-icons/io";

function EditCustomer() {
  const navigate = useNavigate();
  const { id } = useParams(); // Récupère l'ID passé en paramètre

  const [business, setBusiness] = useState([]);
  const [project, setProject] = useState([]);
  const [customerData, setCustomerData] = useState(null);

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const customersCollection = await firestore
          .collection("business")
          .get();
        const temp = customersCollection.docs.map((doc) => ({
          value: doc.data().id,
          label: doc.data().name,
        }));
        setBusiness(temp);
      } catch (error) {
        console.error("Erreur lors de la récupération des business :", error);
      }
    };

    const fetchProject = async () => {
      try {
        const customersCollection = await firestore.collection("project").get();
        const temp = customersCollection.docs.map((doc) => ({
          value: doc.data().id,
          label: doc.data().name,
        }));
        setProject(temp);
      } catch (error) {
        console.error("Erreur lors de la récupération des projets :", error);
      }
    };

    const fetchCustomerData = async () => {
      try {
        const docRef = firestore.collection("customers").doc(id);
        const doc = await docRef.get();
        if (doc.exists) {
          setCustomerData(doc.data());
        } else {
          console.error("Aucun document trouvé avec cet ID");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données du client :",
          error
        );
      }
    };

    fetchBusiness();
    fetchProject();
    fetchCustomerData(); // Appel de la fonction pour récupérer les données du client
  }, [id]);

  const [button, setButton] = useState("none");
  const [emails, setEmails] = useState([""]);
  const [phones, setPhones] = useState([""]);
  const [selectedImage, setSelectedImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    if (customerData) {
      setFirstName(customerData.firstName || "");
      setLastName(customerData.lastName || "");
      setDescription(customerData.description || "");
      setSelectedLanguage(customerData.language || null);
      setSelectedCountry(customerData.country || null);
      setSelectedBusiness(customerData.business || null);
      setSelectedProjects(
        customerData.projects
          ? customerData.projects.map((project) => ({
              value: project,
              label: project, // Assuming project name can be used here
            }))
          : []
      );
      setEmails(customerData.emails || [""]);
      setPhones(customerData.phones || [""]);
      console.log(customerData.imageURL);
      setSelectedImage(customerData.imageURL || "");
    }
  }, [customerData]);

  const handleAddEmailInput = () => {
    setEmails([...emails, ""]);
  };

  const handleAddPhoneInput = () => {
    setPhones([...phones, ""]);
  };

  const handleInputChange = (index, value, inputType) => {
    if (inputType === "email") {
      const newEmails = [...emails];
      newEmails[index] = value;
      setEmails(newEmails);
    } else if (inputType === "phone") {
      const newPhones = [...phones];
      newPhones[index] = value;
      setPhones(newPhones);
    } else if (inputType === "firstName") {
      setFirstName(value);
    } else if (inputType === "lastName") {
      setLastName(value);
    } else if (inputType === "description") {
      setDescription(value);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.size <= 15000000 &&
        (file.type === "image/jpeg" || file.type === "image/png")
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Veuillez sélectionner une image au format JPEG ou PNG de moins de 15MB."
        );
      }
    }
  };

  const handleSave = () => {
    setButton("loading");
    if (
      firstName &&
      lastName &&
      selectedLanguage &&
      selectedCountry &&
      selectedBusiness &&
      selectedProjects.length > 0
    ) {
      if (selectedImage !== "" && selectedImage !== customerData.imageURL) {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`users/${Date.now()}`);
        imageRef
          .putString(selectedImage, "data_url")
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((downloadURL) => {
            storage.refFromURL(customerData.imageURL).delete();
            const customerRef = firestore.collection("customers").doc(id);
            customerRef
              .update({
                imageURL: downloadURL,
                firstName: firstName,
                lastName: lastName,
                description: description ? description : null,
                language: selectedLanguage,
                country: selectedCountry,
                business: selectedBusiness,
                projects: selectedProjects.map((project) => project.value),
                emails: emails,
                phones: phones,
                updatedAt: Date.now(),
              })
              .then(() => {
                console.log("Modifications enregistrées avec succès !");
                navigate("/customer");
              })
              .catch((error) => {
                console.error(
                  "Erreur lors de la sauvegarde des modifications :",
                  error
                );
                setButton("none");
              });
          });
      } else {
        const customerRef = firestore.collection("customers").doc(id);
        customerRef
          .update({
            firstName: firstName,
            lastName: lastName,
            description: description ? description : null,
            language: selectedLanguage,
            country: selectedCountry,
            business: selectedBusiness,
            projects: selectedProjects.map((project) => project.value),
            emails: emails,
            phones: phones,
            updatedAt: Date.now(),
          })
          .then(() => {
            console.log("Modifications enregistrées avec succès !");
            navigate("/customer");
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la sauvegarde des modifications :",
              error
            );
            setButton("none");
          });
      }
    } else {
      alert(
        "Veuillez remplir tous les champs avant de cliquer sur Enregistrer."
      );
      setButton("none");
    }
  };

  return (
    <div className="AddCustomer main">
      <Navbar title="Editer un client" url="/customer" />
      <div className="wrapper">
        {customerData && (
          <div className="form">
            <div className="form-group logo">
              <div className="inner">
                <div className="flex">
                  <div className="container">
                    {selectedImage ? (
                      <img src={selectedImage} alt="form" />
                    ) : (
                      <img
                        src="https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.png"
                        alt="form"
                      />
                    )}
                    <div>
                      <p>Photos de profile</p>
                      <span className="text-sm">PNG, JPG moins de 15MB</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="upload"
                  />
                  <label htmlFor="upload">
                    <p className="button-fill">Charger</p>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group normal">
              <div className="inner">
                <div className="title">
                  <h5>Nom complets</h5>
                </div>
                <div>
                  <Input
                    id="firstname"
                    label="Prénom"
                    placeholder="Louka"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Input
                    id="lastname"
                    label="Nom"
                    placeholder="Mourrier"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group add-input">
              <div className="inner">
                <div className="title">
                  <h5>Contact email</h5>
                </div>
                <div className="flex">
                  <div>
                    {emails.map((email, index) => (
                      <div key={index} className="container">
                        <Input
                          id={`email-${index}`}
                          label={`Email ${index + 1}`}
                          placeholder="email@mail.com"
                          value={email}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value, "email")
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <Button text="Ajouter" fill onClick={handleAddEmailInput}>
                    <div className="ctn-icon-form">
                      <IoMdAdd />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-group add-input">
              <div className="inner">
                <div className="title">
                  <h5>Contact Téléphone</h5>
                </div>
                <div className="flex">
                  <div>
                    {phones.map((phone, index) => (
                      <div key={index} className="container">
                        <Input
                          id={`phone-${index}`}
                          label={`Téléphone ${index + 1}`}
                          placeholder="(33) 781646204"
                          value={phone}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value, "phone")
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <Button text="Ajouter" fill onClick={handleAddPhoneInput}>
                    <div className="ctn-icon-form">
                      <IoMdAdd />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-group normal">
              <div className="inner">
                <div className="title">
                  <h5>Informations</h5>
                </div>
                <div>
                  <div className="select">
                    <span className="text-sm">Pays</span>
                    <Select
                      options={country}
                      value={country.find((c) => c.value === selectedCountry)}
                      onChange={(e) => setSelectedCountry(e.value)}
                    />
                  </div>
                  <div className="select">
                    <span className="text-sm">Langue</span>
                    <Select
                      options={language}
                      value={language.find((l) => l.value === selectedLanguage)}
                      onChange={(e) => setSelectedLanguage(e.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group normal">
              <div className="inner">
                <div className="title">
                  <h5>Description</h5>
                </div>
                <div>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-group normal">
              <div className="inner">
                <div className="title">
                  <h5>Entreprise</h5>
                </div>
                <div>
                  <div className="select">
                    <span className="text-sm">Entreprise</span>
                    <Select
                      options={business}
                      value={business.find((b) => b.value === selectedBusiness)}
                      onChange={(e) => setSelectedBusiness(e.value)}
                    />
                  </div>
                  <div className="select">
                    <span className="text-sm">Projet</span>
                    <Select
                      options={project}
                      value={selectedProjects}
                      isMulti
                      onChange={(selectedOptions) =>
                        setSelectedProjects(selectedOptions)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ctn-button">
              <Button text="Enregistrer" onClick={handleSave} state={button} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditCustomer;
