import React, { useEffect, useState } from "react";
import "./customer.css";
import Navbar from "../../components/bar/navbar";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../components/button";
import Search from "../../components/input/search";
import Pallet from "../../components/tags/pallet";
import { useNavigate, Link } from "react-router-dom";
import { storage, firestore } from "../../components/firebase/firebase";
import {
  formatDate,
  formatDay,
  getCountryName,
  getLanguageName,
  getTimezoneFromCountry,
} from "../../components/function";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

// icons
import { IoIosClose, IoIosTime } from "react-icons/io";
import { HiPencil } from "react-icons/hi";

function Customer() {
  let navigate = useNavigate();

  const [button, setButton] = useState("none");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setButton("disabled");
    // Fonction pour récupérer les données des clients depuis Firebase Firestore
    const fetchCustomers = async () => {
      try {
        const customersCollection = await firestore
          .collection("customers")
          .get();
        const customersData = await Promise.all(
          customersCollection.docs.map(async (doc) => {
            const projectsData = await Promise.all(
              doc.data().projects.map(async (projectId) => {
                const projectDoc = await firestore
                  .collection("project")
                  .doc(projectId)
                  .get();
                if (!projectDoc.exists) {
                  return;
                } else {
                  return {
                    id: projectDoc.data().id, // Supposant que l'ID du projet est stocké dans le champ "id" du document Firestore
                    name: projectDoc.data().name, // Supposant que le nom du projet est stocké dans le champ "name"
                    price: parseInt(projectDoc.data().price), // Supposant que la description du projet est stockée dans le champ "description"
                    priceYear: parseInt(projectDoc.data().priceYear), // Supposant que la description du projet est stockée dans le champ "description"
                    entretien:
                      projectDoc.data().domain.register ||
                      projectDoc.data().host.register,
                    cost:
                      parseInt(projectDoc.data().host.price) +
                      parseInt(projectDoc.data().domain.price), // Supposant que la description du projet est stockée dans le champ "description"
                  };
                }
              })
            );
            const businessDoc = await firestore
              .collection("business")
              .doc(doc.data().business)
              .get();
            return {
              id: `${doc.data().firstName} ${doc.data().lastName}`,
              email: doc.data().emails[0],
              emails: doc.data().emails,
              phone: doc.data().phones[0],
              phones: doc.data().phones,
              created: formatDate(doc.data().createdAt),
              business: businessDoc.data().name, // Supposant que le nom de l'entreprise est stocké dans le champ "name"
              businessImage: businessDoc.data().imageURL,
              update: formatDate(doc.data().createdAt),
              timestamp: doc.data().createdAt,
              image: doc.data().imageURL,
              projects: projectsData.filter((project) => project !== undefined),
              projectSize: projectsData.filter(
                (project) => project !== undefined
              ).length,
              country: getCountryName(doc.data().country),
              language: getLanguageName(doc.data().language),
              timezone: getTimezoneFromCountry(doc.data().country),
              description: doc.data().description,
              docRef: doc.ref,
              index: doc.ref.id,
            };
          })
        );
        setCustomers(customersData);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };

    fetchCustomers();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Nom",
      width: 180,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => handleClick(params.row)}
        >
          <img
            src={params.row.image}
            alt={params.row.id}
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: 20,
              height: 20,
            }}
          />
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
      renderCell: (params) => <div className="active">{params.value}</div>,
    },
    {
      field: "phone",
      headerName: "Téléphone",
      width: 150,
      renderCell: (params) => <div className="active">{params.value}</div>,
    },
    {
      field: "created",
      headerName: "Date création",
      width: 130,
    },
    {
      field: "business",
      headerName: "Entreprise",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={params.row.businessImage}
            alt={params.row.business}
            style={{
              marginRight: "5px",
              borderRadius: "50%",
              width: 15,
              height: 15,
            }}
          />
          {params.value}
        </div>
      ),
    },
    {
      field: "update",
      headerName: "Dernière modification",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
    },
  ];

  const handleClick = (customer) => {
    var modal = document.getElementById("profilePreview");
    if (modal.classList.contains("active")) {
      modal.classList.remove("active");
    } else {
      modal.classList.add("active");
      setSelectedCustomer(customer);
    }
  };

  const handleDelete = async () => {
    setButton("loading");
    try {
      const batch = firestore.batch();
      selectedRows.forEach((row) => {
        const customer = customers.find((c) => c.id === row);
        if (customer && customer.docRef) {
          // Vérifie si la référence au document est définie
          batch.delete(customer.docRef); // Supprime le document Firestore
          // Supprime l'image du storage si nécessaire
          if (
            customer.image !==
            "https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.png"
          ) {
            storage.refFromURL(customer.image).delete();
          }
        }
      });
      await batch.commit();
      const updatedCustomers = customers.filter(
        (customer) => !selectedRows.includes(customer.id)
      );
      setCustomers(updatedCustomers);
      setSelectedRows([]);

      setButton("disabled");
    } catch (error) {
      console.error("Erreur lors de la suppression des clients :", error);
      setButton("none");
    }
  };

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length > 0) {
      setButton("none");
    } else {
      setButton("disabled");
    }
    setSelectedRows(selectionModel);
  };

  return (
    <div className="Customer main">
      <Navbar title="Client" text={customers.length + " Clients"}>
        <Button text="+ Ajouter un client" onClick={() => navigate("add")} />
      </Navbar>
      <div className="wrapper">
        <div className="topbar">
          <Search placeholder="Rechercher" />
          <Button
            text="Supprimer"
            color="red"
            onClick={handleDelete}
            state={button}
          />
        </div>
        <DataGrid
          rows={customers} // Utilisez les données des clients récupérées depuis Firebase
          columns={columns}
          onRowSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          className="table"
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
      <div className="ctn-profile" id="profilePreview">
        <div className="bg" onClick={() => handleClick()}></div>
        <div className="profile">
          <div className="padding">
            <div className="tab">
              <h5>Client Preview</h5>
              <div>
                <Link
                  className="button-fill"
                  to={"edit/" + (selectedCustomer && selectedCustomer.index)}
                >
                  <HiPencil className="icon-button" />
                  Editer
                </Link>
                <IoIosClose className="icon" onClick={() => handleClick()} />
              </div>
            </div>
            <div className="ctn-infos">
              <img
                src={selectedCustomer && selectedCustomer.image}
                alt="logo"
              />
              <div className="ctn-text">
                <div className="ctn-title">
                  <h4>{selectedCustomer && selectedCustomer.id}</h4>
                  <Pallet text="En ligne" online={true} />
                  <Pallet
                    text={
                      selectedCustomer && formatDay(selectedCustomer.timestamp)
                    }
                  >
                    <IoIosTime />
                  </Pallet>
                </div>
                <div className="ctn-business">
                  <img
                    src={selectedCustomer && selectedCustomer.businessImage}
                    alt="icon business"
                    className="logo-entreprise"
                  />
                  {selectedCustomer && selectedCustomer.business}
                </div>
              </div>
            </div>
            <div className="ctn-mid">
              <div>
                <p className="text-sm">Projets</p>
                <h5>{selectedCustomer && selectedCustomer.projectSize}</h5>
              </div>
              <div>
                <p className="text-sm">Prix</p>
                <h5 className="green">
                  {selectedCustomer &&
                    selectedCustomer.projects.reduce(
                      (acc, project) => acc + project.price,
                      0
                    )}
                  €
                </h5>
              </div>
              <div>
                <p className="text-sm">Gains</p>
                <h5 className="green">
                  +
                  {selectedCustomer &&
                    Math.round(
                      (selectedCustomer.projects.reduce(
                        (acc, project) => acc + project.priceYear,
                        0
                      ) /
                        12) *
                        10
                    ) / 10}
                  €/m
                </h5>
              </div>
              <div>
                <p className="text-sm">Coûts</p>
                <h5 className="red">
                  -
                  {selectedCustomer &&
                    Math.round(
                      (selectedCustomer.projects.reduce(
                        (acc, project) => acc + project.cost,
                        0
                      ) /
                        12) *
                        10
                    ) / 10}
                  €/m
                </h5>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Client details</p>
              <div className="flex">
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Téléphone</p>
                    <div>
                      {selectedCustomer &&
                        selectedCustomer.phones.map((phone, index) => (
                          <p className="active" key={index}>
                            {phone}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Email</p>
                    <div>
                      {selectedCustomer &&
                        selectedCustomer.emails.map((email, index) => (
                          <p className="active" key={index}>
                            {email}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Localisation</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.country}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Langue</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.language}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Fuseau horaire</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.timezone}</p>
                    </div>
                  </div>
                </div>
                <div className="inner">
                  <div className="container">
                    <p className="text-sm">Entreprise</p>
                    <div>
                      <div className="ctn-entreprise">
                        <img
                          src={
                            selectedCustomer && selectedCustomer.businessImage
                          }
                          alt="logo"
                          className="logo-entreprise"
                        />
                        <p>{selectedCustomer && selectedCustomer.business}</p>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Description</p>
                    <div>
                      <p>
                        {selectedCustomer &&
                        selectedCustomer.description !== null
                          ? selectedCustomer.description
                          : "Pas de description"}
                      </p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Création</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.created}</p>
                    </div>
                  </div>
                  <div className="container">
                    <p className="text-sm">Modification</p>
                    <div>
                      <p>{selectedCustomer && selectedCustomer.update}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ctn-details">
              <p className="title-sm">Projets</p>
              <div className="list">
                {selectedCustomer &&
                  selectedCustomer.projects.map((project, index) => (
                    <div className="item" key={index}>
                      <p>{project.name}</p>
                      <PDFDownloadLink
                        document={
                          <FacturePDF
                            data={selectedCustomer}
                            project={project}
                          />
                        }
                        fileName="facture.pdf"
                        style={{ textDecoration: "none" }}
                      >
                        {() => (
                          <Button
                            text="Générer une facture"
                            style={{ marginLeft: 10, borderRadius: 30 }}
                          />
                        )}
                      </PDFDownloadLink>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  logo: {
    width: 200,
    height: 100,
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ctnFlex: {
    width: "50%",
  },
  container: {
    width: "45%",
    color: "#5A5B5B",
    fontSize: 12,
    marginTop: 20,
    lineHeight: 1.5,
  },
  title: {
    fontSize: 13,
    color: "black",
    marginBottom: 5,
  },
});

const FacturePDF = ({ data, project }) => {
  return (
    <Document>
      {data && (
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 26 }}>
              Louk<Text style={{ color: "#135bf6" }}>a.</Text>
            </Text>
            <Text style={{ fontSize: 32 }}>Facture</Text>
          </View>
          <View
            style={[
              styles.flex,
              {
                marginTop: 50,
                color: "#5A5B5B",
                fontSize: 11,
                lineHeight: 1.5,
              },
            ]}
          >
            <View style={{ width: 200 }}>
              <Text>Bonjour, {data.id}.</Text>
              <Text>
                Merci d'avoir fait vos achats dans notre magasin et pour votre
                commande.
              </Text>
            </View>
            <View style={[styles.ctnFlex, { textAlign: "right" }]}>
              <Text>Numéro de commande : 80000002</Text>
              <Text>Réf° client : #2849AB</Text>
              <Text>Date : {formatDate(Date.now())}</Text>
            </View>
          </View>
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 80,
                paddingBottom: 5,
                borderBottom: "1px solid #5A5B5B",
                color: "#5A5B5B",
                fontSize: 12,
              }}
            >
              <Text style={{ width: 260 }}>Produit</Text>
              <Text style={{ width: 100 }}>Réf°</Text>
              <Text style={{ width: 100 }}>Quantité</Text>
              <Text>Prix</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 10,
                paddingBottom: 10,
                borderBottom: "1px solid #eee",
                color: "#5A5B5B",
                fontSize: 12,
              }}
            >
              <Text style={{ width: 260, color: "#135bf6" }}>
                Site Internet {project.name}
              </Text>
              <Text style={{ width: 100 }}>#284HF69</Text>
              <Text style={{ width: 100 }}>1</Text>
              <Text>{project.price}€</Text>
            </View>
            {project.entretien && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottom: "1px solid #eee",
                  color: "#5A5B5B",
                  fontSize: 12,
                }}
              >
                <Text style={{ width: 260, color: "#135bf6" }}>
                  Maintenance (hébergement, ...)
                </Text>
                <Text style={{ width: 100 }}>#3857YS8</Text>
                <Text style={{ width: 100 }}>1</Text>
                <Text>{project.priceYear}€</Text>
              </View>
            )}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 20,
                color: "#5A5B5B",
                fontSize: 12,
                lineHeight: 1.5,
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginRight: 30,
                }}
              >
                <Text>Sous-total : </Text>
                <Text>TVA : </Text>
                <Text style={{ fontSize: 14, marginTop: 5, color: "black" }}>
                  Total :
                </Text>
              </View>
              <View style={{ display: "flex", alignItems: "flex-end" }}>
                <Text>{(project.price + project.priceYear) * 0.85}€</Text>
                <Text>15%</Text>
                <Text style={{ fontSize: 14, marginTop: 5, color: "black" }}>
                  {project.price + project.priceYear}€
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 20,
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <View style={styles.container}>
              <Text style={styles.title}>INFORMATIONS FACTURATION</Text>
              <Text>{data.id}</Text>
              <Text>4 Rue de la liberté</Text>
              <Text>Grenoble 38000, France</Text>
              <Text>Téléphone : {data.phone}</Text>
              <Text>Email : {data.email}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.title}>MODE DE PAIEMENT</Text>
              <Text>Carte de crédit</Text>
              <Text>Type de carte de crédit : Visa</Text>
              <Text>Numéro de transaction : 4185939336</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.title}>INFORMATIONS LIVRAISON</Text>
              <Text>{data.id}</Text>
              <Text>4 Rue de la liberté</Text>
              <Text>Grenoble 38000, France</Text>
              <Text>Téléphone : {data.phone}</Text>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default Customer;
