import React from "react";
import "./inputComponent.css";

function Input({
  label,
  type,
  placeholder,
  width,
  onChange,
  children,
  id,
  maxLength,
  value,
}) {
  return (
    <div className="InputComponents" style={{ width: width }}>
      <label htmlFor={id} className="text-sm">
        {label}
      </label>
      <label className="container" id={id} htmlFor="input">
        {children}
        <input
          type={type ? type : "text"}
          id="input"
          placeholder={placeholder}
          onChange={onChange && onChange}
          maxLength={maxLength}
          value={value}
          onFocus={() => {
            const container = document.getElementById(id);
            container.className = "container active";
          }}
          onBlur={() => {
            const container = document.getElementById(id);
            container.className = "container";
          }}
        />
      </label>
    </div>
  );
}

export default Input;
