import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Home from "./pages/home/index.jsx";
import Sidebar from "./components/bar/sidebar.jsx";
import Customer from "./pages/customer/index.jsx";
import AddCustomer from "./pages/customer/add.jsx";
import Business from "./pages/business/index.jsx";
import AddBusiness from "./pages/business/add.jsx";
import Project from "./pages/project/index.jsx";
import AddProject from "./pages/project/add.jsx";
import EditCustomer from "./pages/customer/edit.jsx";
import EditBusiness from "./pages/business/edit.jsx";
import EditProject from "./pages/project/edit.jsx";
import { app } from "./components/firebase/firebase.js";

function App() {
  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      element: <Sidebar />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/business",
          element: <Business />,
        },
        {
          path: "/business/add",
          element: <AddBusiness />,
        },
        {
          path: "/business/edit/:id",
          element: <EditBusiness />,
        },
        {
          path: "/project",
          element: <Project />,
        },
        {
          path: "/project/add",
          element: <AddProject />,
        },
        {
          path: "/project/edit/:id",
          element: <EditProject />,
        },
        {
          path: "/customer",
          element: <Customer />,
        },
        {
          path: "/customer/add",
          element: <AddCustomer />,
        },
        {
          path: "/customer/edit/:id",
          element: <EditCustomer />,
        },
      ],
    },
  ]);

  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        // window.location.href = "https://dashboard.loukaaa.com/";
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
